/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    searchBS: ""
  },
  data() {
    return {
      showSeach: false,
      searchVal: ""
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/index");
    },
    onClickRight() {
      this.showSeach = true;
    },
    onSearch() {}
  }
};